import React, { useEffect, useState } from "react";

import { Button } from "@mui/material";
import Header from "../components/common/Header";
function AccessControl() {
  // const [firstName, setFirstName] = useState("John")
  // const [lastName, setLastName] = useState("Doe")
  // const [email, setEmail] = useState("johndoe@gmail.com")
  // const [editingMode, setEditingMode] = useState(false)

  return (
    // Navbar
    <Header letters={"AC"} information={"Access Control"} />
  );
}

export default AccessControl;
